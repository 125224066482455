<template>
  <v-dialog v-model="show" persistent width="400">
    <v-card>
      <v-card-title>
        <span>Imprimir comprobante</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('cancel')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col v-if="error" cols="12">
            <v-alert type="error" dense>{{ error }}</v-alert>
          </v-col>
          <v-col cols="12">
            <span v-if="isFirstTime"
              >La venta se ha generado con éxito ¿Deseas imprimir el
              comprobante? (imprimir ticket)</span
            >
            <span v-else
              >¿Desea imprimir el comprobante de la venta
              {{ sale.saleNumber }}?</span
            >
          </v-col>
          <v-col cols="12">
            <v-btn color="secondary" block text @click="$emit('cancel')">
              <span>No, cerrar</span>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="secondary"
              block
              :loading="loading"
              @click="printSale()"
            >
              <v-icon small class="mr-2">mdi-ticket-confirmation</v-icon>
              <span>Sí, imprimir comprobante</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import serverRequestMixin from "@/mixins/serverRequest.mixin";

export default {
  mixins: [serverRequestMixin],

  props: {
    show: { type: Boolean, default: false },
    sale: { type: Object, required: true },
    isFirstTime: { type: Boolean, default: true },
  },

  data: () => ({
    loading: false,
    error: "",
  }),

  methods: {
    async printSale() {
      this.loading = true;
      this.error = null;

      try {
        let urlString = `/sales/${this.sale._id}/document`;

        const serverResponse = await this.getFileRequest(urlString);
        this.loading = false;

        var file = new Blob([serverResponse.file], { type: "application/pdf" });
        let fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.$emit("cancel");
      } catch (error) {
        this.handleResponseError(error);
      }
    },
  },
};
</script>
